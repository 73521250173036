import '../css/app-index.scss';

require('owl-carousel-2/owl.carousel');

let apiData = null;

function drawVisualization() {
  let dataTable = [['Country', 'Value', { role: 'tooltip', p: { html: true } }]];

  for (let key in apiData) {
    if (!apiData.hasOwnProperty(key)) {
      continue;
    }

    let years = apiData[ key ].join(', '),
      count = apiData[ key ].length,
      country = key.toString();

    dataTable.push([country, count, years]);
  }

  let data = google
    .visualization
    .arrayToDataTable(dataTable);

  let chart = new google
    .visualization
    .GeoChart(document.getElementById('visualization'));

  chart
    .draw(data, {
      colors: ['#3788d6'],
      tooltip: {
        isHtml: true
      },
      height: 500,
    });
}

$(function () {
  $('.owl-carousel')
    .owlCarousel({
      center: true,
      lazyLoad: true,
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      items: 1
    });

  /**
   * API for travel data from database
   */
  $.ajax({
    url: properUrl,
    method: 'POST',
    dataType: 'json',
  }).done(function (data) {
    apiData = data[ 'data' ];

    google
      .load('visualization', '1.1', {
        packages: ['geochart'],
        callback: drawVisualization
      });
  });
});


$.fn.andSelf = function () {
  return this.addBack.apply(this, arguments);
};
